@import "./globals.scss";
@import "./buttons.scss";
@import "./input.scss";
@import "./table.scss";
@import "./livekit.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap');

body,
html,
#__next {
  @apply h-full;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  // hide overscroll
  overscroll-behavior: none;
}

a {
  @apply text-blue-600 cursor-pointer;

  &:hover {
    @apply underline;
  }
}

h1,
.h1 {
  @apply text-4xl font-semibold sm:text-5xl md:text-4xl;
}

h2,
.h2 {
  @apply text-3xl font-semibold sm:text-4xl;
}

h3,
.h3 {
  @apply text-xl font-semibold sm:text-3xl;
}

h4,
.h4 {
  @apply text-lg  sm:text-xl;
}

.tab-item {
  @apply relative pb-2;
}

.tab-item::after {
  @apply absolute bottom-0 left-1/2 w-0 h-0.5 bg-[#002082] transition-all duration-300;
  content: '';
}

.tab-item-active::after {
  @apply left-0 w-full;
}


form label {
  @apply text-sm uppercase text-slate-600 block mb-1;
}

.success {
  display: block;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.description {
  @apply text-sm md:text-lg text-slate-600;
}

.shadow-custom {
  box-shadow: 0 1.5rem 4rem rgb(0 0 0 / 10%);
}

header .menu a,
.footerMenu a {
  @apply text-slate-500;

  &:hover {
    @apply text-slate-800;
  }
}

.breadcrumbs {
  a {
    @apply text-slate-400;

    &:hover {
      @apply text-slate-200;
    }
  }
}

.footerMenu img {
  @apply opacity-60;

  &:hover {
    @apply opacity-100;
  }
}

.bootcampRow {
  .bg-image {
    @apply transition-all duration-300 ease-in-out;
  }

  &:hover {
    .bg-image {
      background-size: 102%;
    }
  }
}

.heroTextGradient {
  background: linear-gradient(
    to left,
    rgba(0, 224, 255, 1) 0%,
    rgba(250, 0, 255, 1) 33%,
    rgba(0, 224, 255, 1) 66%,
    rgba(250, 0, 255, 1) 100%
  );
  background-repeat: repeat-x;
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: loopy 12s linear infinite;
}

// livekit
[data-lk-theme="default"] {
  --lk-control-bar-height: 100px !important;
}

.lk-button,
.lk-disconnect-button {
  font-size: 12px !important;
  border-radius: 50px;
}

.lk-chat-toggle.notify {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background: #ff0000;
    border-radius: 50%;
  }
}

// scrolling animation

#maindiv {
  border: 2px solid black;
  overflow: hidden;
  white-space: nowrap;
}

.zmwebsdk-makeStyles-root-52 {
  position: fixed !important;
  z-index: 1 !important;
  top: 10px !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 10px !important;
}
.zmwebsdk-makeStyles-root-58 {
  margin: -50px auto !important;
}

#meetingSDKElement,
#meetingSDKElement * {
  max-height: 100vh !important;
  max-width: 100vw !important;
}

#div1 {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

#div2 {
  display: inline-block;
  animation: marquee2 10s linear infinite;
  animation-delay: 5s;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

.badge {
  @apply px-3 py-1 bg-slate-100 rounded-lg text-sm text-slate-800 font-semibold min-w-[60px] inline-flex text-center;

  &.badge-green {
    @apply bg-green-500 bg-opacity-10 text-green-500;
  }

  &.badge-red {
    @apply bg-red-500 bg-opacity-10 text-red-500;
  }

  &.badge-orange {
    @apply bg-orange-500 bg-opacity-10 text-orange-500;
  }

  &.badge-primary {
    @apply bg-primary bg-opacity-10 text-primary;
  }

  &.badge-sm {
    @apply px-2 py-0.5 text-sm;
  }
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.slider {
  height: 500px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;

  .slide-track {
    display: flex;
    width: calc(500px * 5);
    animation: scroll 30s linear infinite;
  }
}
.slide {
  height: auto;
  width: 500px;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0)
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider:before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px * 5);
  }
}

.loader-2 {
  display: block;
  height: 32px;
  width: 32px;
  -webkit-animation: loader-2-1 3s linear infinite;
  animation: loader-2-1 3s linear infinite;
}
@-webkit-keyframes loader-2-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(16px, 32px, 32px, 0);
  -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid transparent;
  border-top: 3px solid #578989;
  border-radius: 50%;
  -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-3 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid #ffffff50;
  border-radius: 50%;
}

.rotate {
  animation: rotate 30s linear infinite;
}

.rotate-slow {
  animation: rotate 40s linear infinite;
}

.rotate-reverse {
  @apply rotate-45;
  animation: rotate-reverse 30s linear infinite;
}

.slide-in {
  animation: slideIn 3s linear infinite;

  &:hover {
    scale: 1.1;
  }
}

.bounce {
  animation: bounce 1s linear infinite;
}

.ganesh-chaturthi {
  background-image: linear-gradient(45deg, #d43428, #ff958e) !important;

  &.birthday-week-hidden-overflow {
    overflow: hidden !important;
  }

  &.poppers {
    background-color: #04dbbd !important;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 530%;
      width: 100px;
      //bg-img
      background-image: url("/img/popper.gif");
      //bg-fit
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 530%;
      width: 100px;
      //bg-img
      background-image: url("/img/popper.gif");
      //bg-fit
      background-size: contain;
      transform: rotateY(180deg);
      background-repeat: no-repeat;
    }
  }

  &.button {
    background-image: linear-gradient(45deg, #d43428, #ff958e) !important;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10%;
      z-index: 0;
      height: 100%;
      width: 100px;
      //bg-img
      background-image: url("/img/ganesh.gif");
      //bg-fit
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.banner {
    span {
      // color: black !important;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100px;
      //bg-img
      background-image: url("/img/rangoli.gif");
      //bg-fit
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100px;
      //bg-img
      background-image: url("/img/rangoli.gif");
      //bg-fit
      background-size: contain;
      transform: rotateY(180deg);
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
}

/*# sourceMappingURL=index.css.map */

// rotate image
@keyframes rotate {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(1turn);
  }
}

// rotate reverse image
@keyframes rotate-reverse {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(-1turn);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-1000px) rotate(12deg);
  }
  100% {
    transform: translateX(400px) rotate(12deg);
  }
}

// bounce text from right to left animation
@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
// zoom overlap

.zmwebsdk-MuiPaper-rounded {
  border-radius: 0 !important;
}

.text-balance {
  white-space: balance !important;
}

.great-indian-learning-festival {
  &.banner {
    background-color: #f5b14f;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: -21px;
      left: -21px;
      height: 100%;
      opacity: 43%;
      aspect-ratio: 1/1;
      background-image: url("/img/gilf-btn-decor.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      position: absolute;
      right: -21px;
      bottom: -21px;
      aspect-ratio: 1/1;
      height: 100%;
      background-image: url("/img/gilf-btn-decor.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &.btn-gilf {
    background-color: #f5b14f;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: -21px;
      left: -21px;
      height: 100%;
      aspect-ratio: 1/1;
      background-image: url("/img/gilf-btn-decor.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      position: absolute;
      right: -21px;
      bottom: -21px;
      aspect-ratio: 1/1;
      height: 100%;
      background-image: url("/img/gilf-btn-decor.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.text-balance {
  white-space: balance;
}

@keyframes bubble-slow {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 100%;
  }

  100% {
    transform: translate3d(25px, -90vh, 0);
    opacity: 0%;
  }
}

.likeBubble {
  position: absolute;
  bottom: 0;
  animation: bubble-slow 4.25s linear 1 forwards;
  font-size: xx-large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.likeBubbleName {
  background: white;
  padding: 0px 3px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  opacity: 80%;
  font-size: 10px !important;
}

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.list-custom {
  li {
    /* OR */
    list-style-type: "👉";
    padding-inline-start: 10px;
  }
  p {
    margin-top: 20px;
  }
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;

  &--animation-slow {
    animation: confetti-slow 4.25s linear 1 forwards;
  }

  &--animation-medium {
    animation: confetti-medium 3.75s linear 1 forwards;
  }

  &--animation-fast {
    animation: confetti-fast 3.25s linear 1 forwards;
  }
}

@keyframes hoverdiya {
  0% {
    transform: translate3d(0, -5px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, -5px, 0);
  }
}

.diwali {
  background: radial-gradient(
    #a84d1f 21%,
    #923f15 56%,
    #732c08 100%
  ) !important;
  &.btn {
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      bottom: -33px;
      left: -33px;
      height: 150%;
      aspect-ratio: 1/1;
      background-image: url("/img/diwali/diwali-decor-2.png");
      filter: opacity(40%);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: 0px;
      aspect-ratio: 1/1;
      height: 100%;
      background-image: url("/img/diwali/diya-collection.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.hover-animation {
  animation: hoverdiya 6s ease-in-out infinite;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
