@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@layer utilities {
  .custom-gradient {
    background: linear-gradient(
        271deg,
        #085f56 0.27%,
        rgba(38, 211, 103, 0.49) 98.62%
      ),
      linear-gradient(0deg, #085f56 0%, #085f56 100%), #0a2133;
  }
}

.header-text-shadow {
  color: #0a2133;
  -webkit-text-stroke: 0.25px transparent;
}

:hover.header-text-shadow {
  color: #0a2133;
  -webkit-text-stroke: 0.25px #0a2133;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
